<template>
  <component :is="hdtemplate" :hd="hd" :gList="gList" :goodList="goodList" v-if="hdtemplate" ref="theme"/>
</template>

<script>
  //1、普通模板 2、七夕专场 3、爱心专场 4、端午专场
export default {
  name:'seckill',
  props: {
    hd:Object,
    gList:Array,
    goodList:Array
  },
  components: {},
  data() {
    return {
      hdtemplate:null,
    }
  },
  mounted() {
    this.hdtemplate = () => import(`./components/${this.hd.template}.vue`)
  },
  methods: {
  
  }
}
</script>
<style lang="less">

</style>
